<template>
  <v-dialog v-model="value" persistent hide-overlay width="500">
    <v-card>
      <v-card-title :id="customId + '__cardTitle'">
        <span class="headline"> {{ titleText }} </span>
        <v-spacer></v-spacer>
        <span class="overline"> </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text :id="customId + '__cardText'" class="py-6">
        {{ messageText }}
        <span class="deleteItemText" v-if="itemName != ''"
          >[{{ itemName }}]</span
        >
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <!-- <v-btn
          class="ma-2"
          color="success"
          :id="customId + '__btnApprove'"
          @click.native="$emit('verify')"
        >
          {{ $t("common.yes") }}
        </v-btn> -->
        <!-- <v-btn
          :id="customId + '__btnDisapprove'"
          class="ma-2"
          @click="$emit('input', false)"
        >
          {{ $t("common.no") }}
        </v-btn> -->

        <v-btn
          name="yes"
          color="success"
          icon
          :id="customId + '__btnApprove'"
          @click.native="$emit('verify')"
        >
          <v-icon color="primary">
            mdi-checkbox-marked-circle
          </v-icon>
        </v-btn>
        <v-btn
          name="no"
          :id="customId + '__btnDisapprove'"
          @click="$emit('input', false)"
          icon 
        >
          <v-icon>
            mdi-close-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CustomVerificationDialog",

  props: {
    value: {
      type: Boolean,
      required: true
    },
    titleText: {
      type: String,
      required: true
    },
    sideTitleText: {
      type: String,
      required: true
    },
    itemName: {
      type: String,
      required: true
    },
    messageText: {
      type: String,
      required: true
    },
    isAlert: {
      type: Boolean,
      required: true
    },
    customId: {
      type: String,
      default: "verificationDialog",
      required: false
    }
  }
};
</script>
