import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import i18n from "./resources/i18n.js";
import VueLogger from 'vuejs-logger';
import formatters from '@/util/formatters';
import validation from '@/util/validation.js';
import utils from '@/util/utils';
import constants from '@/commons/constants';
import VueToastify from "vue-toastify";


Vue.prototype.$formatters = formatters;
Vue.prototype.$validations = validation;
Vue.prototype.$constants = constants;
Vue.prototype.$utils = utils;

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug', //logLevels :  ['debug', 'info', 'warn', 'error', 'fatal']
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);
Vue.use(VueToastify, {
    errorDuration: 3000,
    successDuration: 2000,
    canTimeout: 2000,
    canPause: true,
    position: "bottom-right",
    singular: false,
    hideProgressbar: false,
    defaultTitle: false,
});

sync(store, router)

Vue.config.productionTip = false

var odApp = new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')

export default odApp;