<template>
  <section class="mb-12 text-left">
    <h1
      class="mb-2 text-h2"
      v-text="heading"
    />

  </section>
</template>

<script>
  export default {
    name: 'ViewIntro',

    props: {
      heading: String,
      link: String,
    },
  }
</script>
