// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
    drawer: null,
    drawerImage: true,
    mini: false,
    items: [
        // {
        //     title: 'Dashboard',
        //     icon: 'mdi-view-dashboard',
        //     to: '/',
        // },
        // {
        //     title: 'Bejelentkezés',
        //     icon: 'mdi-view-dashboard',
        //     to: '/Login',
        // },
        // {
        //     title: 'BejelentkezésC',
        //     icon: 'mdi-view-dashboard',
        //     to: '/LoginC',
        // },
        // {
        //     title: 'Igénykezelő',
        //     icon: 'mdi-animation',
        //     to: '/UserProfile3',
        // },
        {
            title: 'PossibilityHandler',
            icon: 'mdi-animation',
            to: '/PossibilityHandler/',
        },
        // {
        //     title: 'Igénykezelő2',
        //     icon: 'mdi-animation',
        //     to: '/UserProfileCopy/',
        // },
        // {
        //     title: 'UserProfile',
        //     icon: 'mdi-account',
        //     to: '/UserProfile/',
        // },
        // {
        //     title: 'RegularTables',
        //     icon: 'mdi-clipboard-outline',
        //     to: '/tables/regular/',
        // },
        // {
        //     title: 'Typography',
        //     icon: 'mdi-format-font',
        //     to: '/components/typography/',
        // },
        // {
        //     title: 'Icons',
        //     icon: 'mdi-chart-bubble',
        //     to: '/components/icons/',
        // },
        // {
        //     title: 'GoogleMaps',
        //     icon: 'mdi-map-marker',
        //     to: '/maps/google/',
        // },
        // {
        //     title: 'Notifications',
        //     icon: 'mdi-bell',
        //     to: '/components/notifications/',
        // },
    ],


    loggedInUserObj: null,
    loggedInUserId: null,
    loggedInUserUsername: null,

    statusCode: null,
    statusAdditionalMessage: null,
    snackbarEnabled: false,
    loading: false,
    asyncProgress: [],
    myLoading: false,

    loggedInUserRights: [],
}

// const mutations = make.mutations(state)
const mutations = {
    ...make.mutations(state),
    setStatusCode(state, payload) {
        state.statusCode = payload;
    },
    setStatusAdditionalMessage(state, payload) {
        state.statusAdditionalMessage = payload;
    },
    setSnackbarEnabled(state, payload) {
        state.snackbarEnabled = payload;
    },
    clearStatusCode(state) {
        state.statusCode = null;
    },
    clearStatusAdditionalMessage(state) {
        state.statusAdditionalMessage = null;
    },
    setLoading(state, payload) {
        state.myLoading = payload;
    },
    setAsyncProgress(state, key) {
        let idx = state.asyncProgress.indexOf(key);
        if (idx > -1) {
            state.asyncProgress.splice(idx, 1);
        } else {
            state.asyncProgress.push(key);
        }
    },
    setLoggedInUserObj(state, payload) {
        state.loggedInUserObj = payload;
    },
    setLoggedInUserId(state, payload) {
        state.loggedInUserId = payload;
    },
    setLoggedInUserUsername(state, payload) {
        state.loggedInUserUsername = payload;
    },
    setLoggedInUserRights(state, payload) {
        state.loggedInUserRights = payload;
    },
}

const actions = {
    ...make.actions(state),
    init: async ({ dispatch }) => {
        //
    },
}

const getters = {
    asyncProgress: state => state.asyncProgress,
    isAuthenticated: state => !!state.loggedInUserObj,
    getLoggedInUserObj: state => state.loggedInUserObj,
    getLoggedInUserId: state => state.loggedInUserId,
    loggedInUserUsername: state => state.loggedInUserUsername,
    getLoggedInUserRights: state => state.loggedInUserRights,

    statusCode: state => state.statusCode,
    statusAdditionalMessage: state => state.statusAdditionalMessage,
    snackbarEnabled: state => state.snackbarEnabled,
    loading: state => state.loading,
    myLoading: state => state.myLoading,

    getLoggedInUserRights: state => state.loggedInUserRights,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
