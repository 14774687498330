export default {
    DUMMYDataPOST: {
        '/api/authentication/login_': {
            data: {
                data: {
                    "success": true,
                    "token": "abcdefg"
                },
                error: {
                    code: 0,
                    trace: [],
                    message: "success"
                }
            },
            status: {}
        },
        '/api/authentication/logout_': {
            data: {
                data: {},
                error: {
                    code: 0,
                    trace: [],
                    message: "success"
                }
            },
            status: {}
        },
        '/api/possibility_': {
            "data": {
                "data": {
                    "success": true,
                    "id": 122
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": [

                    ]
                }
            },
            status: {}
        },
    },
    DUMMYDataPATCH: {
        '/api/possibility/3_': {
            "data": {
                "data": {
                    "success": true
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": [

                    ]
                }
            },
            status: {}
        },
    },
    DUMMYDataDELETE: {
        '/api/possibility/3_': {
            data: {
                data: {
                    success: true,
                },
                status: 200
            }
        },
    },
    DUMMYDataGET: {
        '/api/authentication/isauthenticated_': {
            data: {
                data: {
                    "authenticated": true
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": []
                },
            },
        },
        '/api/possibility/init_': {
            data: {
                data: {
                    "status": [
                        {
                            "key": "new",
                            "value": "new"
                        },
                        {
                            "key": "in_progress",
                            "value": "in_progress"
                        },
                        {
                            "key": "interested",
                            "value": "interested"
                        },
                        {
                            "key": "not_interested",
                            "value": "not_interested"
                        },
                        {
                            "key": "build_later",
                            "value": "build_later"
                        },
                        {
                            "key": "preparatory_hearing",
                            "value": "preparatory_hearing"
                        },
                        {
                            "key": "plan_in_progress",
                            "value": "plan_in_progress"
                        },
                        {
                            "key": "awaiting_final_plan",
                            "value": "awaiting_final_plan"
                        },
                        {
                            "key": "contract_preparation",
                            "value": "contract_preparation"
                        },
                        {
                            "key": "contract_concluded",
                            "value": "contract_concluded"
                        },
                        {
                            "key": "aborted_failed",
                            "value": "aborted_failed"
                        },
                        {
                            "key": "closed",
                            "value": "closed"
                        }
                    ]
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": []
                },
            },
        },
        '/api/possibility_': {
            data: {
                data: [
                    {
                        id: 1,
                        customer: "Kokas Zoltán",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budaörs",
                        loc_site_size: "111",
                        financing_budget: "150000000",
                        status: "new",
                        created: "2021-01-15T15:03",
                        remember_datetime_1: "2021-04-27T15:03",
                    },
                    {
                        id: 2,
                        customer: "Pálffy Sándor",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budapest",
                        loc_site_size: "50",
                        financing_budget: "105000000",
                        status: "in_progress",
                        created: "2021-01-17T11:00",
                        remember_datetime_1: "2021-04-25T15:03",
                    },
                    {
                        id: 3,
                        customer: "Farkas Réka",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budapest",
                        loc_site_size: "70",
                        financing_budget: "15000000",
                        status: "in_progress",
                        created: "2021-03-15T15:00",
                        remember_datetime_1: "2021-03-23T15:03",
                    },
                    {
                        id: 4,
                        customer: "Kiss Tamás",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budapest",
                        loc_site_size: "77",
                        financing_budget: "30000000",
                        status: "new",
                        created: "2021-02-15T15:00",
                        remember_datetime_1: "2021-02-17T15:03",
                    },
                    {
                        id: 5,
                        customer: "Nagy ELemér",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budaörs",
                        loc_site_size: "88",
                        financing_budget: "20000000",
                        status: "new",
                        created: "2021-02-15T15:00",
                        remember_datetime_1: "2021-04-12T15:03",
                    },
                    {
                        id: 6,
                        customer: "Kokas Tamás",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Debrecen",
                        loc_site_size: "56",
                        financing_budget: "10000000",
                        status: "new",
                        created: "2021-01-15T15:03",
                        remember_datetime_1: "2021-04-11T15:03",
                    },
                    {
                        id: 7,
                        customer: "Fekete Béla",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "Budapest",
                        loc_site_size: "85",
                        financing_budget: "25000000",
                        status: "new",
                        created: "2021-01-10T10:00",
                        remember_datetime_1: "2021-02-12T15:03",
                    },
                    {
                        id: 8,
                        customer: "Kokas Zoltán",
                        customer_email: "kokas.zoltan@on-demand.hu",
                        customer_phone: "+36203208793",
                        loc_city: "ADMIN1",
                        loc_site_size: "alma",
                        financing_budget: "17000000",
                        status: "new",
                        created: "2021-01-10T11:00",
                        remember_datetime_1: "2021-04-06T15:03",
                    }
                ]
            },
            status: 200
        },
        '/api/user/details_': {
            data: {
                data: {
                    "username": "administrator",
                },
                "error": {
                    "code": 0,
                    "message": "Success.",
                    "trace": []
                },
            },
            status: 200
        },
        '/api/possibility/3_': {
            data: {
                data: {
                    id: "3",

                    customer_first_name: "Farkas",
                    customer_last_name: "Réka",
                    customer_phone: "123456789",
                    customer_email: "reka.f@gmail.com",
                    customer_zipcode: "2040",
                    customer_city: "Budaörs",
                    customer_address: "Levendula u. 8",

                    loc_zipcode: "2040",
                    loc_city: "Budaörs",
                    loc_address: "Levendula u. 8",
                    loc_parcel_number: "1234",
                    loc_site_type: "A",
                    loc_site_size: "1100",
                    loc_site_classification: "Belterület",
                    loc_site_water: true,
                    loc_site_electricity: true,
                    loc_site_gas: true,
                    loc_site_sewage: true,

                    planned_building_new_or_expansion: true,
                    planned_building_has_plan: true,
                    loc_site_size: "100",
                    planned_building_unique_or_type: true,
                    planned_building_create_date_year: "2022",
                    planned_building_create_date_quarter: "2",

                    financing_budget: "25000000",
                    financing_own_budget: "55000000",
                    financing_need_loan: true,
                    financing_loan: "20000000",

                    remember_datetime_1: "2022-05-01T08:00",
                    remember_text_1: "",
                    remember_datetime_2: "2022-05-11T09:00",
                    remember_text_2: "",
                    remember_datetime_3: "2022-05-21T10:00",
                    remember_text_3: "",

                    comment: "nincs semmi megjegyzés",

                    created: "2021-03-15T15:00",
                },
            },
            status: 200
        },
    }
}

