import axios from 'axios';
import store from '@/store';
import DUMMYResponses from '@/commons/DUMMYResponses';
import constants from '@/commons/constants';
import { ODException } from '@/commons/ODException';
import router from '@/router';
import Authentication from '@/commons/Authentication';
import odApp from '@/main';


function odAppLog(...args) {
    if (odApp === undefined) {
        console.log(args);
    } else {
        odApp.$log.debug(args);
    }
}

export function isString(value) {
    return typeof value === 'string';
};

export function isNumber(value) {
    return typeof value === 'number';
};

export function isPresent(value) {
    return value !== null && value !== undefined;
};

export function arraySort(value, sortBy) {
    // Example sortBy format
    // sortBy = [{
    //     prop: 'grade',
    //     direction: -1
    // }, {
    //     prop: 'lastName',
    //     direction: 1
    // }];

    value.sort(function (a, b) {
        let i = 0, result = 0;
        while (i < sortBy.length && result === 0) {
            result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
            i++;
        }
        return result;
    })
    return value
};

export function apiCaller(method, url, entity = null, needSnackbar = false, needErrorSnackbar = true, response = null, headers = null) {
    // if (nrgApp !== undefined){
    //     nrgApp.$log.debug("API Caller Url: ", url);
    //     nrgApp.$log.debug("API Caller Method: ", method);
    // }
    odAppLog("API Caller Url: ", url);
    odAppLog("API Caller Method: ", method);

    let DUMMYData = null;
    switch (method) {
        case "post":
            DUMMYData = DUMMYResponses.DUMMYDataPOST
            break
        case "put":
            DUMMYData = DUMMYResponses.DUMMYDataPATCH
            break
        case "delete":
            DUMMYData = DUMMYResponses.DUMMYDataDELETE
            break
        case "get":
        default:
            DUMMYData = DUMMYResponses.DUMMYDataGET
            break
    }

    // Add token for laravel authorization
    if ([constants.HTTP_METHODS.POST, constants.HTTP_METHODS.PUT, constants.HTTP_METHODS.PATCH].indexOf(method) >= 0) {
      if (isPresent(entity)) {
        entity.token = sessionStorage.getItem("token");
      } else {
        entity = {token: sessionStorage.getItem("token")}
      }
    } else {
        if (isPresent(entity) && isPresent(entity.params)) {
            entity["params"]["token"] = sessionStorage.getItem("token");
        } else if (isPresent(entity) && !isPresent(entity.params)) {
            entity["params"] = { token: sessionStorage.getItem("token") };
        } else {
            entity = { "params": { "token": sessionStorage.getItem("token") } }
        }
    }


    store.commit('app/setLoading', true);
    //console.log("DUMMYData: ", DUMMYData);
    // console.log("DUMMYData res: ", DUMMYData[url]);
    // console.log("url: ", url);

    if (response !== null) {
        //nrgApp.$log.debug("==[ Called with Response ]==");
        odAppLog("==[ Called with Response ]==");
        store.commit('app/setLoading', false);
        return Promise.resolve(response);
    } else if (constants.DUMMY_DATA_HANDLING && DUMMYData.hasOwnProperty(url)) {
        odAppLog("==[ Found DUMMY Data ]==");

        odAppLog("==[ Request params ]==");
        odAppLog(entity);
        odAppLog("==[ Data ]==");
        odAppLog(DUMMYData[url]);
        // nrgApp.$log.debug("==[ Found DUMMY Data ]==");
        // nrgApp.$log.debug(DUMMYData[url]);
        store.commit('app/setLoading', false);
        return Promise.resolve(DUMMYData[url]);
    } else {
        odAppLog("==[ Not found DUMMY Data ]==");
        odAppLog("==[ Request params ]==");
        odAppLog(entity);

        // nrgApp.$log.debug("==[ Not found DUMMY Data ]==");
        return new Promise((resolve, reject) => {
            store.commit('app/setAsyncProgress', method + url);

            axios[method](url, entity, headers)
                .then(response => {
                    // nrgApp.$log.debug("==[ apiCaller success ]==");
                    odAppLog("==[ apiCaller success ]==");
                    store.commit('app/clearStatusAdditionalMessage');
                    if (needSnackbar === true) {
                        store.commit('app/setStatusCode', response.status);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    odAppLog("==[ apiCaller Catch ]==");
                    odAppLog("=========", error.response);
                    store.commit('app/setSnackbarEnabled', false);

                    const odError = new ODException(console, error);
                    store.commit('app/setStatusCode', odError.getCode());
                    store.commit('app/setStatusAdditionalMessage', odError.getMessage());

                    if (odError.getCode() == constants.NOT_LOGGED_ERROR_CODE) {
                        if (sessionStorage.getItem("token") != ""){
                            store.commit('app/setSnackbarEnabled', true);
                        }
                        Authentication.resetLoggedInUser()
                        router.push({ name: "Login" });
                    } else {
                        if (needErrorSnackbar === true) {
                            store.commit('app/setSnackbarEnabled', true);
                        }
                    }
                    reject(odError);
                })
                .finally(() => {
                    store.commit('app/setLoading', false);
                    store.commit('app/setAsyncProgress', method + url);
                });

        });
    }
};

const utils = {
    isString: isString,
    isNumber: isNumber,
    isPresent: isPresent,
    apiCaller: apiCaller,
    arraySort: arraySort
};

export default utils;
