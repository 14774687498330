import "@/styles/overrides.sass";

export default {
    name: "App",
    metaInfo: {
        title: "CRM",
        titleTemplate: "%s | HázamLesz CRM",
        htmlAttrs: { lang: "hu" },
        meta: [
            { charset: "utf-8" },
            { name: "viewport", content: "width=device-width, initial-scale=1" }
        ]
    },

    data() {
        return {
            snackbarEnabled: this.$store.getters.snackbarEnabled,
        }
    },
    computed: {
        // loggedIn() {
        //     return this.$store.getters.isAuthenticated;
        // },
        // loggedUsername() {
        //     return this.$store.getters.getLoggedInUserUsername;
        // },
        statusCode() {
            let msg = "";
            if (!this.$store.getters["app/statusCode"]) {
                msg = "TODO";
            } else {
                msg = this.$store.getters["app/statusCode"].toString();
            }
            return msg;
        },
        // statusAdditionalMessage() {
        //     return this.$store.getters.statusAdditionalMessage ? this.$store.getters.statusAdditionalMessage : '';
        // },
        storeSnackbarEnabled() {
            return this.$store.getters["app/snackbarEnabled"];
        },
    },
    watch: {
        snackbarEnabled() {
            this.$store.commit('app/setSnackbarEnabled', this.snackbarEnabled);
            if (this.snackbarEnabled) {
                // Prepare snackbar text
                let localizationTextKey = "status." + this.statusCode;
                let mainText = this.$i18n.t(localizationTextKey);
                let text = (this.statusAdditionalMessage) ? mainText + '<br /><br /><span class=\'snackbarAdditionalMessage\'>' + this.statusAdditionalMessage + '</span>' : mainText;

                if (this.snackbarEnabled) {
                    if (this.statusCode[0] == "2") {
                        this.$vToastify.success(text, this.$i18n.t("common.snackbar.SUCCESS"));
                    } else {
                        this.$vToastify.error(text, this.$i18n.t("common.snackbar.ERROR"));
                    }
                }
            }
            this.$store.commit('app/setSnackbarEnabled', false);
        },
        storeSnackbarEnabled() {
            this.snackbarEnabled = this.storeSnackbarEnabled;
        },
    }
};

