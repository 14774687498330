<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" md="12" class="text-right ma-0 pa-0 pt-4 pb-1">
        <div class="body-1 font-weight-light ma-0 pa-0 pt-md-0 text-right">
          <a
            href="http://on-demand.hu"
            target="_blank"
            class="text-decoration-none"
            ><img
              src="/on-demand-logo.png"
              class="on-demand-logo"
              style="height: 30px"
          /></a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Links",

  data: () => ({
    // links: [
    //   {
    //     href: "https://vuetifyjs.com/",
    //     text: "Vuetify Docs"
    //   },
    //   {
    //     href: "#",
    //     text: "About Us"
    //   },
    //   {
    //     href: "#",
    //     text: "Blog"
    //   },
    //   {
    //     href: "#",
    //     text: "Licenses"
    //   }
    // ]
  })
};
</script>

<style lang="sass" scoped>
a
  color: inherit !important
</style>
