// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import { trailingSlash } from '@/util/helpers'

import {
    layout,
    route,
} from '@/util/routes'
import Authentication from '@/commons/Authentication';

Vue.use(Router)



const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters["app/isAuthenticated"]) {
        next();
        return
    }
    router.push('/Login');
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters["app/isAuthenticated"]) {
        next(trailingSlash(to.path));
        return
    } else {
        console.debug("Try isAuthenticated");
        Authentication.isAuthenticated()
            .then((resp) => {
                console.debug("Response isAuthenticated: ", resp)
                if (resp && resp.data.data.authenticated == true) {

                    Authentication.getMyDetails()
                        .then((response) => {
                            console.debug("Response getMyDetails: ", response)
                            next(trailingSlash(to.path));
                            return
                        })
                        .catch(error => {
                            console.debug(error);
                            next(trailingSlash('/Login'));
                        });
                } else {
                    console.log("You are not logged");
                    next(trailingSlash('/Login'));
                }
                return
            })
            .catch(error => {
                console.debug(error);
                next(trailingSlash('/Login'));
            });
    }
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash }
        if (savedPosition) return savedPosition

        return { x: 0, y: 0 }
    },
    routes: [
        layout('Default', [
            // route('Dashboard', 'Dashboard', 'Dashboard', 'Dashboard'),
            route('Root', 'Dashboard', '/', '', '/PossibilityHandler'),

            // Pages
            route('PossibilityHandler', 'PossibilityHandler', "PossibilityHandler"),

            // Components
            
            route('UserProfile', null, 'UserProfile'),
            route('Notifications', null, 'components/notifications'),
            route('Icons', null, 'components/icons'),
            route('Typography', null, 'components/typography'),

            // Tables
            route('Regular Tables', null, 'tables/regular'),

            // Maps
            route('Google Maps', null, 'maps/google'),
        ]),
        layout('SimpleDefault', [
            route('Login', 'Login', "Login"),
        ]),
    ],
})

router.beforeEach((to, from, next) => {
    return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
